const menus = [  
    {
        id: 1,
        name: 'Nosotros',
        links: '/#nosotros'
    },    
    {
        id: 2,
        name: 'Productos',
        links: '/#productos'
    },
    {
        id: 3,
        name: 'Truck',        
        links: '/#truck'
    },
    {
        id: 4,
        name: 'Contacto',
        links: '/#contacto'
    },
   
  
    
]

export default menus;