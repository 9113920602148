const item = {
    item8 : require('../images/items/item-08.png'),
    item9 : require('../images/items/item-09.png'),
    item10 : require('../images/items/item-10.png'),   
    item27 : require('../images/items/item-08.png'),
    item28 : require('../images/items/item-09.png'),
    item29 : require('../images/items/item-10.png'),
    technology : require('../images/items/item-10.png'),
}

export default item;